import { useCallback, useRef, memo, useEffect, useMemo, useState } from 'react'

import { format } from 'date-fns'
import { ModalFile, ModalFileRef } from 'modals/modal-file/modal-file'

import iconMapMarker from 'assets/images/icon-map-marker.png'
import mallMarker from 'assets/images/mall-marker.png'

import InfoItem from 'components/_new-general-vision/info-item/info-item'
import { AccessComponent } from 'components/access-component/access-component'
import { Canvas } from 'components/canvas/canvas'
import { FileItem } from 'components/file-item/file-item'
import { Grid } from 'components/grid/grid'
import { TagProblem } from 'components/tag-problem/tag-problem'
import { TrackerCode } from 'components/tracker-code/tracker-code'

import { formatCEP, formatCurrency, formatOrderItemsToString, formatPhone, getStatusOrder } from 'helpers'
import { useAuth, useMapboxView } from 'hooks'
import { addMultipleSymbolPoints } from 'hooks/useMapboxView/helpers'
import { IAttachment, IDestiny, Order } from 'types'

import {
    DetailsTabContainer,
    PhoneIcon,
    PickerContainer,
    PickerName,
    PickerPhone,
    PickerPhoto,
    PickerTextContainer,
    PickerTitle,
    InfoSection,
    SectionTitle,
    InfoRow,
    MapContainer,
    ScheduleButton,
    EditIcon,
    CollectButton,
    Tag,
    PointTag,
} from './details-tab.styles'

interface Props {
    order: Order | undefined
    onRescheduleOrder(order: Order): void
}

const DetailsTab: React.FC<Props> = memo(({ order, onRescheduleOrder }) => {
    const modalFileRef = useRef<ModalFileRef>()
    const { user, mall } = useAuth()

    const { mapRef, mapContainerRef } = useMapboxView({})

    const isPresential = useMemo(() => {
        return !['delivery', 'cargo'].includes(order?.type)
    }, [order])

    const deliveryType = useMemo(() => {
        if (order.type === 'takeout') {
            return 'Retirada em Balcão'
        }
        if (order.type === 'indoor') {
            return 'Atendimento em Mesa'
        }
        if (order.type === 'delivery') {
            return 'Delivery'
        }
    }, [order.type])

    const _openFile = useCallback((attachment: IAttachment) => {
        modalFileRef.current?.show({ attachment })
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (order?.address?.lat && order?.address?.lng) {
                addMultipleSymbolPoints(mapRef.current, [
                    {
                        id: 'map-marker',
                        src: iconMapMarker,
                        coordinates: { lat: order.address.lat, lng: order.address.lng },
                    },
                    {
                        id: 'mall-marker',
                        src: mallMarker,
                        size: 0.4,
                        coordinates: { lat: mall.address.lat, lng: mall.address.lng },
                    },
                ])
            }
            mapRef.current?.setZoom(14)
            mapRef.current?.resize()
        }, 1000)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <DetailsTabContainer>
                <Grid.Row>
                    <Tag>
                        <PointTag />
                        {deliveryType}
                    </Tag>
                    <Tag>
                        <PointTag />
                        {getStatusOrder(order.status)}
                    </Tag>
                    <TagProblem hasProblem={order.has_problem} problems={order.problems} />
                </Grid.Row>
                <AccessComponent roles={['owner', 'staff']} type="hide">
                    <>
                        {order?.status && !['1', '3'].includes(order?.status) && (
                            <>
                                <PickerContainer>
                                    {!!order?.agent?.avatar && <PickerPhoto src={order?.agent?.avatar} />}
                                    <PickerTextContainer>
                                        <PickerTitle>Responsável pela entrega</PickerTitle>
                                        <PickerName>{order?.agent?.name || 'Aguardando entregador'}</PickerName>
                                        {user.isMallManager && (
                                            <>
                                                {order?.agent && (
                                                    <PickerPhone>
                                                        <PhoneIcon /> {formatPhone(order?.agent?.cellphone, true)}
                                                    </PickerPhone>
                                                )}
                                            </>
                                        )}
                                    </PickerTextContainer>
                                </PickerContainer>
                            </>
                        )}
                    </>
                </AccessComponent>

                {!!order && <TrackerCode orderId={order.id} />}

                <InfoSection>
                    <SectionTitle>Informações Sobre o Pedido</SectionTitle>
                    <InfoRow>
                        <InfoItem label="Lojista" content={order?.merchant.name} />
                        <InfoItem label="Número do Pedido" content={order?.reference_id} />
                    </InfoRow>
                    <InfoRow>
                        <InfoItem
                            label="Canal de venda"
                            content={order?.sales_channel?.name || order?.reference_name}
                        />
                        {order?.sales_channel?.id === 2 && (
                            <InfoItem label="Código de entrega" content={order?.receiver?.code || 'Não informado'} />
                        )}
                    </InfoRow>
                    {order?.sales_channel?.id === 2 && (
                        <InfoRow>
                            <InfoItem label="Identificador de origem" content={order.order_id || 'Não informado'} />
                        </InfoRow>
                    )}
                    <InfoRow>
                        <InfoItem label="Forma de Pagamento" content={order?.payment.name} />
                        <InfoItem label="Valor do Pedido" content={formatCurrency(order?.total_price)} />
                    </InfoRow>
                </InfoSection>
                <InfoSection>
                    <SectionTitle>Coleta</SectionTitle>
                    <InfoRow>
                        <InfoItem
                            label="Local de coleta"
                            content={order?.delivery_origin?.name || order?.merchant?.name}
                        />
                    </InfoRow>

                    <InfoRow>
                        <InfoItem
                            label="Requer comprovação de entrega?"
                            content={Number(order?.collect?.confirmation_delivery) ? 'SIM' : 'Não'}
                        />
                        <InfoItem
                            label="Requer comprovação de problema?"
                            content={Number(order?.collect?.confirmation_fail) ? 'SIM' : 'Não'}
                        />
                    </InfoRow>
                    <InfoRow>
                        <InfoItem
                            label="Requer comprovação de coleta?"
                            content={Number(order?.collect?.confirmation_required) ? 'SIM' : 'Não'}
                        />
                        {!!order?.collect?.observations && (
                            <InfoItem label="Observações sobre a coleta" content={order?.collect?.observations} />
                        )}
                    </InfoRow>

                    <InfoRow>
                        {!!order?.attachments?.request_collect?.length && (
                            <InfoItem
                                label="Anexos"
                                content={
                                    <>
                                        {order.attachments.request_collect.map((item, i) => (
                                            <FileItem key={i} isView attachment={item} onClick={_openFile} />
                                        ))}
                                    </>
                                }
                            />
                        )}
                        <InfoItem label="" />
                    </InfoRow>
                    <InfoRow>
                        {order?.attachments?.collect && !!order.attachments.collect.length && (
                            <InfoItem
                                label="Comprovante de coleta"
                                content={
                                    <>
                                        {order.attachments.collect.map((item, i) => (
                                            <FileItem key={i} isView attachment={item} onClick={_openFile} />
                                        ))}
                                    </>
                                }
                            />
                        )}
                    </InfoRow>
                    <InfoRow>
                        {order?.attachments?.delivery && !!order.attachments.delivery.length && (
                            <InfoItem
                                label="Comprovante de entrega"
                                content={
                                    <>
                                        {order.attachments.delivery.map((item, i) => (
                                            <FileItem key={i} isView attachment={item} onClick={_openFile} />
                                        ))}
                                    </>
                                }
                            />
                        )}
                    </InfoRow>
                    <InfoRow>
                        {order?.attachments?.fail && order.attachments.fail.length > 0 && (
                            <InfoItem
                                label="Comprovante de problema"
                                content={
                                    <>
                                        {order.attachments.fail.map((item, i) => (
                                            <FileItem key={i} isView attachment={item} onClick={_openFile} />
                                        ))}
                                    </>
                                }
                            />
                        )}
                    </InfoRow>
                </InfoSection>
                <InfoSection>
                    <SectionTitle>Cliente</SectionTitle>
                    <InfoRow>
                        <InfoItem label="Nome do Cliente" content={order?.customer.name} />
                        <InfoItem
                            label="Telefone"
                            content={
                                order?.customer?.phone?.includes('+55')
                                    ? formatPhone(order?.customer?.phone)
                                    : order?.customer?.phone
                            }
                        />
                    </InfoRow>
                    <InfoRow>
                        <InfoItem label="E-mail do cliente" content={order?.customer.email ?? '-'} noMargin />
                    </InfoRow>
                </InfoSection>

                {(window.location.pathname.includes('em-andamento') || window.location.pathname.includes('novos')) &&
                    [1, 3].includes(Number(order?.status)) && (
                        <InfoSection>
                            <SectionTitle>Agendamento</SectionTitle>

                            {order?.delivery_forecast ? (
                                <ScheduleButton onClick={() => order && onRescheduleOrder(order)}>
                                    {format(order?.delivery_forecast, 'DD/MM/YYYY - HH:mm')} <EditIcon />
                                </ScheduleButton>
                            ) : (
                                <CollectButton onClick={() => order && onRescheduleOrder(order)}>
                                    AGENDAR ENTREGA DO PEDIDO
                                </CollectButton>
                            )}
                        </InfoSection>
                    )}

                <InfoSection>
                    <SectionTitle>Endereço</SectionTitle>
                    <InfoRow>
                        <InfoItem label="CEP" content={formatCEP(order?.address?.zipcode)} />
                        <InfoItem label="Bairro de entrega" content={order?.address?.neighborhood as string} />
                    </InfoRow>
                    <InfoRow>
                        <InfoItem label="Logradouro" content={order?.address?.street} />
                        <InfoItem label="Número" content={order?.address?.number} />
                    </InfoRow>
                    <InfoRow>
                        <InfoItem label="Complemento" content={order?.address?.complement} />
                        <InfoItem label="Referência" content={order?.address?.landmark} />
                    </InfoRow>
                </InfoSection>
                {isPresential && order.destinies.length ? (
                    <CanvasComponent destinies={order.destinies} />
                ) : (
                    <MapContainer ref={mapContainerRef}></MapContainer>
                )}

                <InfoSection>
                    <SectionTitle>Itens e Observações</SectionTitle>
                    <InfoItem label="Itens" contentList={formatOrderItemsToString(order?.items || []).split('\n')} />
                    <InfoItem label="Observações sobre a entrega" content={order?.observation} />
                </InfoSection>
            </DetailsTabContainer>
            <ModalFile ref={modalFileRef} />
        </>
    )
})

type CanvasComponentProps = {
    destinies: IDestiny[]
}
const CanvasComponent: React.FC<CanvasComponentProps> = memo(({ destinies }) => {
    const [destiny, setDestiny] = useState<IDestiny>()

    useEffect(() => {
        if (destinies.length > 0) {
            setDestiny(destinies[0])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    if (!destiny) {
        return null
    }
    return (
        <>
            <Canvas destiny={destiny} />
        </>
    )
})

export { DetailsTab }
